@import "_variables.scss";

.post-frequency-and-rounding-container {
  .app-label-seperator {
    padding-top: 3rem;
    padding-bottom: 1rem;
  }
  .frequency-module-container{
    padding-top: 3rem;
  }
}
