@import "_variables.scss";
.box-interstial {
  width: 478px;
  border-radius: 5px;
  border: 1px solid $color-border;
  padding: 20px;
  gap: 24px;
  background-color: $color-default-white;
}
.stepper-intertial[data-orientation="vertical"] {
  top: var(--chakra-space-24);
}
.stepper-intertial[data-status="complete"] {
  z-index: 1;
  background: $color-icon-deactivated !important;
}

.stepper-intertial[data-status="incomplete"] {
  background: transparent !important;
}

.stepper-intertial[data-status="active"] {
  background: linear-gradient(
    to bottom,
    transparent 0.1rem,
    $color-default-black 0.1rem,
    $color-default-black 0.2rem,
    transparent 0.2rem
  ) !important;
}

.step-interstial {
  width: 100%;
  margin-bottom: var(--chakra-space-12);
  flex-direction: column;
  &.active-step-interstial {
    display: flex;
    margin-bottom: var(--chakra-space-4);
  }
}
.divider-step-active-interstial {
  opacity: 1;
  border-right: 2px dashed $color-link-hover-color;
  border-left-width: 0;
  height: 68px;
}
.divider-interstial-down-arrow {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: var(--chakra-space-6);
}
.divider-interstial-with-text {
  display: flex;
  flex-direction: row;
}
.divider-text {
  margin-left: 24px;
  margin-top: 55px;
  font-family: $font-family-sfmono;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.arrow-with-divider-interstial {
  margin-top: calc(var(--chakra-space-2) * -1);
  width: var(--chakra-space-10);
  height: var(--chakra-space-10);
  color: $color-link-hover-color;
}
.title-heading {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}
.description {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: $color-font-card;
  margin-top: var(--chakra-space-3);
}
.image-active-interstial {
  border: $color-icon-deactivated;
}
