@import "_variables.scss";

.component-config-interstitial-screen-container {
  width: 670px;
  margin: 0 auto var(--chakra-space-48) auto;
  .heading-text {
    font-family: $font-family-regular;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    margin-top: 24px;
  }
  .description-text {
    font-family: $font-family-regular;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
  }
  .content-container {
    width: 437px;
    padding: 32px;
    border-radius: 16px;
    border: 2px solid $color-nav-selected;
    margin: 36px auto 0 auto;
    background-color: $color-default-white;
    .content-heading-text {
      font-family: $font-family-regular;
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      text-align: center;
    }
    .content-sub-heading-text {
      font-family: $font-family-sfmono !important;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      margin-top: 16px;
      text-transform: uppercase;
    }
    .items {
      margin-top: 16px;

      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
    }
    .added-items {
      margin-top: 0;
      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
    }
  }
  .component-divider {
    border-color: $color-border;
    margin-top: var(--chakra-space-6-4);
    opacity: 1 !important;
  }
}
