@import "_variables.scss";

.algolia-global-search-container {
  overflow-y: auto;
  @media screen and (max-width: $breakpoint-mobile-desktop) {
    overflow-y: unset;
  }
  .filter-container {
    display: flex;
    flex-direction: column !important;
    align-items: flex-end;
    margin-right: 0;
    margin: var(--chakra-space-12) 0 var(--chakra-space-14);
    .filter-button {
      max-width: 240px;
      min-width: 240px;
      height: 46px;
      background-color: $color-default-white;
      border: 1px solid $color-font-card;
      border-radius: 8px;
      justify-content: space-between;
      .filter-by {
        text-align: left;
        font-size: $font-size-reg;
        font-weight: $font-weight-regular;
      }
      &.clear-context {
        height: 30px;
        margin-bottom: var(--chakra-space-4);
      }
    }
  }
}
