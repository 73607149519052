@import "_variables.scss";

.recent-products {
  .recent-product-header {
    .recent-product-title {
      font-family: $font-family-regular;
      font-size: $font-size-l;
      font-weight: var(--chakra-fontWeights-semibold);
    }
    .recent-product-desc {
      font-family: $font-family-regular;
      font-size: $font-size-reg;
      font-weight: var(--chakra-fontWeights-normal);
    }
  }

  .recent-products-container {
    align-items: center;
    margin: 0 var(--chakra-space-12-8) var(--chakra-space-12-8)
      var(--chakra-space-12-8);
    .recent-products-default-img {
      flex: 1;
    }
    .recent-products-text {
      font-size: $font-size-reg;
      font-weight: var(--chakra-fontWeights-normal);
      flex: 4;
      ul {
        margin: var(--chakra-space-4-8) 0 var(--chakra-space-4-8)
          var(--chakra-space-12);
        li {
          line-height: 24px;
        }
      }
    }
  }

  .recent-products-table {
    .constructed-action-cell {
      text-align: right;
    }
    .constructed-name-cell {
      column-gap: var(--chakra-space-2);
      align-items: center;
      justify-content: space-between;
    }
    .custom-table-container .custom-table-data-row {
      font-size: $font-size-small;
      min-width: 6rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .custom-table-data-row:last-child {
        min-width: 16rem;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .product-link {
    float: right;
    margin-top: var(--chakra-space-10);
    margin-bottom: var(--chakra-space-12);
  }

  .recent-product-table {
    thead {
      tr {
        background-color: $color-bg-badge;
        th {
          font-size: $font-size-reg;
          font-weight: var(--chakra-fontWeights-medium);
          text-transform: none;
          color: $color-default-black;
          padding: var(--chakra-space-4) var(--chakra-space-4);
        }
        .custom-table-header-data-item {
          font-size: $font-size-reg !important;
        }
        .custom-table-header-data {
          cursor: pointer;
        }
      }
    }

    tbody {
      .menu-list-container {
        min-width: 9%;
        width: var(--chakra-sizes-40);
        padding: var(--chakra-space-4) var(--chakra-space-2-5);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        border-radius: var(--chakra-radii-3xl);
        box-shadow: 0 var(--chakra-space-2) var(--chakra-space-6) 0
          $color-box-shadow;
        button {
          justify-content: center;
          padding: var(--chakra-space-6-4);
          font-size: $font-size-reg;
          font-weight: var(--chakra-fontWeights-bold);
          &:hover {
            background-color: $color-sidebar-hover-bg;
            border-radius: $border-radius-small;
          }
          &:focus {
            background-color: $color-sidebar-hover-bg;
            border-radius: $border-radius-small;
          }
        }
      }
      .tag-default {
        width: var(--chakra-sizes-3xs);
      }

      .warning-count {
        text-align: left;
        margin-top: var(--chakra-space-2);
        color: $color-font-badge-warning;
      }

      .error-count {
        text-align: left;
        margin-top: var(--chakra-space-2);
        color: $color-border-error;
      }
    }
  }
}
