@import "_variables.scss";

.jumpstart-activation-content {
  align-items: center;
  padding: 0 var(--chakra-space-8);
  justify-content: space-between;
  margin-bottom: var(--chakra-space-12);
  h4 {
    font-size: $font-size-2rem;
  }
  .jumpstart-activation-status {
    font-size: $font-size-xs;
    font-weight: var(--chakra-fontWeights-semibold);
    text-align: right;
    margin-right: var(--chakra-space-4);
    flex: 1 0 15%;
  }
}
