@import "_variables.scss";

.completion-main-container {
  width: 63%;
  margin: var(--chakra-space-12) auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.completion-main-card-container {
  margin-top: calc(var(--chakra-space-8) * -1);
  width: 100%;
  padding: var(--chakra-space-9-6);
  margin-bottom: var(--chakra-space-40);
}

.heading-content-divider {
  width: auto;
  margin-bottom: var(--chakra-space-12-8);
  color: #d3d4d5;
}

.completion-main-desc {
  font-family: $font-family-regular;
  font-size: $font-size-reg;
  font-weight: $font-weight-regular;
  text-align: center;
  margin-bottom: var(--chakra-space-12-8);
  margin-top: var(--chakra-space-12);
}

.completion-main-heading {
  font-family: $font-family-regular;
  font-size: $font-size-l;
  font-weight: $font-weight-semi-bold;
  text-align: center;
}

.completed-item {
  padding: var(--chakra-space-8);
  border: 1px solid $color-border;
  border-radius: var(--chakra-space-3-2);
  display: flex;
  align-items: flex-start;
  position: relative;
  z-index: 9;
}

.completed-item:last-child {
  margin-bottom: var(--chakra-space-12-8);
}

.completed-item-text {
  font-family: $font-family-regular;
  font-size: $font-size-2rem;
  font-weight: $font-weight-semi-bold;
  margin-left: var(--chakra-space-8);
}

.completed-item-line {
  margin-left: var(--chakra-space-12);
  height: var(--chakra-space-6-4);
  border-left: 2px solid $color-border;
}

.completed-item-line:last-child {
  display: none;
}

.interstial-screen-footer {
  min-height: var(--chakra-size-32);
  background: $color-btn-secondary-hover;
  margin: 0 auto;
  padding: var(--chakra-space-4) var(--chakra-space-6);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
