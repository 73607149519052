@import "_variables.scss";

.add-comps-title-container {
  a {
    display: flex;
  }
  font-size: $font-size-xl;
  display: flex;
  align-items: center;
}
.add-features-container {
  display: flex;
  gap: var(--chakra-space-12-8);
  padding: 0 var(--chakra-space-12-8);
  margin-bottom: calc($config-form-footer-height + var(--chakra-space-12-8));

  .features-listing {
    flex: 0 1 70%;
    padding: var(--chakra-space-12-8);
    button {
      color: $color-dark-grey;
    }
    .features-list {
      background-color: $color-bg-badge;
      color: $color-default-black;
      font-size: $font-size-reg;
      line-height: 24px;
      padding: var(--chakra-space-4);
      border-radius: 8px 8px 0 0;
      margin-top: var(--chakra-space-6-4);
      &.flex-acenter {
        margin-top: 0;
      }
      .w-15 {
        width: var(--chakra-space-8);
      }
    }
    .fx-accordion {
      border-bottom: 1px solid $color-border;
      .chakra-collapse {
        overflow: visible !important;
      }
      .feature-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: var(--chakra-space-7) var(--chakra-space-4);
        .feature-title {
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 600;
        }
      }

      .feature-container[aria-expanded="true"] {
        background-color: $color-surface-blue-light;
        .feature-name {
          color: $color-surface-text-blue;
        }
        .fx-accordion-icon {
          color: $color-surface-text-blue;
        }
      }
      .wrapper-add-btn {
        width: var(--chakra-space-39-6);
        display: flex;
        align-items: flex-end;
        flex-direction: column;
      }
      .btn-added-feature,
      .btn-add-feature {
        cursor: pointer;
        border: 1px solid $color-font-card;
        display: flex;
        padding: var(--chakra-space-3-2) 0px var(--chakra-space-3-2)
          var(--chakra-space-4-8);
        border-radius: 8px;
        max-width: 72px;
        align-items: center;
        height: 28px;
        min-width: 58px;
        span {
          font-size: $font-size-small;
          font-weight: $font-weight-semi-bold;
          margin-right: var(--chakra-space-4);
        }
        svg {
          width: 1rem;
          height: 1rem;
          margin-right: var(--chakra-space-3-2);
        }
      }
      .btn-add-feature:disabled {
        cursor: default;
        border-color: $toggle-not-checked;
        color: $toggle-not-checked;
      }

      .btn-added-feature {
        max-width: 105px;
        border: 1px solid $color-link-hover-color;
        background-color: $color-link-hover-color;
        color: $color-default-white;
        font-size: $font-size-small;
        line-height: 20px;
        font-weight: 600;

        span {
          position: relative;
          top: 1px;
          font-weight: $font-weight-semi-bold;
          font-size: $font-size-small;
        }
      }
      .fx-accordion-icon {
        margin-right: var(--chakra-space-4-8);
        color: $color-default-font-color;
        height: 2rem;
        width: 2rem;
      }
      .feature-name {
        font-size: $font-size-small;
        line-height: 20px;
        color: $color-default-font-color;
        word-break: break-word;
      }
      .accordian-description {
        border-top: 1px solid $color-gradient-light-orange;
        padding: var(--chakra-space-5) var(--chakra-space-13);
      }
    }
  }
  .added-features-details {
    flex: 0 0 30%;
    .required-features {
      padding: var(--chakra-space-12-8);
      padding-right: 0;
      margin-bottom: var(--chakra-space-12-8);
      border-radius: $border-radius-16;
      background-color: $color-sidebar-hover-bg;
      .required-features-listing {
        margin-top: var(--chakra-space-6-4);
        .flex {
          margin-bottom: var(--chakra-space-6-4);
          align-items: center;
          svg {
            margin-right: var(--chakra-space-2);
            circle:first-child {
              fill: transparent;
            }
          }
          .form-label-container {
            align-items: center;
            .form-label {
              font-size: $font-size-reg;
              line-height: 24px;
              color: $color-font-card;
              font-weight: normal;
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .added-features-container {
      margin-bottom: var(--chakra-space-40);
      padding: var(--chakra-space-12-8);
      border: 1px solid $color-link-hover-color;
      border-radius: 16px;
      color: $color-default-font-color;
      .desc {
        font-size: $font-size-xs;
        line-height: 16px;
        margin: var(--chakra-space-6-4) 0;
      }
      .added-features {
        .feature {
          justify-content: space-between;
          .feature-name {
            font-size: $font-size-reg;
            line-height: 24px;
            color: $color-font-card;
            font-weight: 400;
            word-break: break-word;
          }
          &:not(:last-child) {
            margin-bottom: var(--chakra-space-6-4);
          }
        }
      }

      .no-feature-added {
        color: $color-description-txt;
        font-size: $font-size-xs;
        margin-top: 3rem;
      }
    }
  }
}

.configured-product-footer {
  height: $config-form-footer-height;
  background: $color-btn-secondary-hover;
  padding: 0 var(--chakra-space-6);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.app-modal-content {
  &.frequency-module-modal-selector {
    max-width: 638px !important;
    height: 257px;
    margin-top: 171px;
    .app-modal-body {
      padding: var(--chakra-space-9-6);
      height: 250px;
    }
    .app-btn-reg-secondary {
      background-color: $color-default-white;
      color: $color-link-hover-color;
      border-color: transparent;
    }
    .app-modal-title {
      width: 100%;
      margin-bottom: var(--chakra-space-12-8);
      height: 45px;
      padding: 0;
    }
    .chakra-modal__close-btn {
      display: none;
    }
    .chakra-modal__body {
      width: 100%;
      padding: 0;
    }
    .code-input {
      margin-top: var(--chakra-space-4-8);
      width: 100%;
      line-height: 24px;
      font-size: $line-height-xs;

      .chakra-input {
        width: 398px;
        height: 48px;
        padding: var(--chakra-space-4-8) var(--chakra-space-6-4);
        border-radius: 8px;
        border: 1px;
        border: 1px solid $color-font-card;
        font-family: $font-family-regular;
        font-size: $font-size-reg;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
      }
      .bottom-divider {
        border: 1px solid $color-border;
      }
    }
    footer {
      width: 100%;
      min-height: 46px;
      padding: 0;
      margin: 0;
    }
  }
}
