.reward-app-form-container {
  row-gap: var(--chakra-space-12-8);
  .common-title {
    margin-bottom: 0;
  }
  .custom-duration-input {
    .app-form-field-container {
      margin-bottom: 0;
    }
  }
  .app-card-container {
    .reward-number-input {
      .app-number-input-wrapper {
        .app-number-input-container {
          width: 30%;
        }
      }
    }
  }
}
