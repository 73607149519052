@import "_variables.scss";

.product-launchpad-start-confif-primary-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 0.8rem;
  .app-btn-inverse-secondary{
    margin-top: var(--chakra-space-11);
    margin-bottom: var(--chakra-space-1);

  }

  .product-launchpad-start-confif-link {
    width: fit-content;
    padding: 1rem;
    font-weight: 700;
    font-size: 16px;
    color: $color-default-white;
    background-color: $color-default-black;
    border-radius: 0.6rem;
    display: flex;
    column-gap: 0.5rem;
    margin: 2rem 0;
  }
}

.product-launchpad-config-divider {
  display: flex;
  align-items: center;

  .line {
    flex: 1;
    height: 1px;
    background-color: $color-border;
    margin: 0 2rem;
  }
}

.product-launchpad-secondary-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 0.8rem;
  margin: var(--chakra-space-14) 0 var(--chakra-space-14) 0;
}
.primary-heading {
  font-size: 20px;
  font-weight: 700;
}
.sub-text {
  font-size: 16px;
}

.product-launchpad-table-link {
  color: $color-link-hover-color;
  display: flex;
  column-gap: 1rem;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 1rem;
  font-weight: 700;
  font-size: 1.2 rem;
  border: 0.1rem solid $color-link-hover-color;
  border-radius: 0.5rem;
  line-height: 1.7rem;
}
.chakra-modal__close-btn:hover{
  color: $color-link-hover-color;
}
