@import "_variables.scss";

.testing-read-container {
  margin-left: 18.5%;
  margin-right: 18.5%;
  .testing-read-heading {
    padding-top: var(--chakra-space-20);
  }
  .testing-read-head-paragraph {
    padding-top: var(--chakra-space-10);
  }
  .doc-card-container {
    img {
      width: var(--chakra-space-8) ;
      height: var(--chakra-space-8);
      align-self: flex-start;
    }
    .doc-card-content {
      width:fit-content;
      display: flex;
      margin-left: var(--chakra-space-5);
      flex-direction: column;
      gap: $gap-small;
      .title {
        font-size: $font-size-med;
        font-weight: $font-weight-bold;
      }
      .sub-title {
        font-size: $font-size-reg;
        font-weight: $font-weight-medium;
      }
    }
    .doc-tag-arrow-card {
      display:flex;
      padding-top: var(--chakra-space-1);
      align-self: center;
      margin-left: auto;
      font-size: $font-size-small;
      justify-content: flex-end;
    }
    .category-tag {
      font-family: $font-family-sfmono-medium;
      text-transform: uppercase;
      background-color: $color-sidebar-hover-bg;
      color: $color-link-hover-color;
      border-radius: $border-radius-xlarge;
      margin-right: var(--chakra-space-4);
      padding: var(--chakra-space-2) var(--chakra-space-4) var(--chakra-space-2) var(--chakra-space-4);
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: var(--chakra-space-4);
    }
  }
}
