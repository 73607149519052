@import "_variables.scss";
.dashboard-main-content {
  width: 100%;
  .dashboard-accordion-components {
    width: 100%;
    .accordion-item {
      border: none;
      .chakra-collapse {
        overflow: visible !important;
      }

      .accordion-container {
        .accordion-button {
          border-top: 1px solid $color-border;
          display: flex;
          align-items: center;
          padding: var(--chakra-space-6);
          div {
            align-items: center;
          }
          &:hover {
            overflow: hidden;
            background: $color-bg-badge;
            cursor: pointer;
          }
          &.last-item:hover {
            border-radius: 0 0 14px 14px;
            overflow: hidden;
          }
          .chakra-step__separator {
            width: 0px;
            border-left: 1px solid $color-description-txt;
          }
          .css-4t6ey5[data-orientation="vertical"] {
            max-height: 100%;
            z-index: 9;
            margin-left: var(--chakra-space-6);
          }
          .chakra-step__indicator[data-status="active"] {
            border-color: transparent;
            background-color: transparent;
            border: none;
          }
          .chakra-step__indicator[data-status="incomplete"] {
            border-color: transparent;
            background-color: transparent;
            border: none;
          }
          .chakra-step__indicator[data-status="complete"] {
            border-color: transparent;
            background-color: transparent;
          }

          .accordion-description-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            margin-left: var(--chakra-space-4);

            .accordion-title {
              font-family: $font-family-regular;
              color: $color-default-font-color;
              font-size: $font-size-reg;
            }

            .accordion-title-bold {
              font-weight: var(--chakra-fontWeights-bold);
            }
            .accordion-limit-name {
              font-family: $font-family-regular;
              color: $color-font-card;
              font-size: $font-size-xs;
              font-weight: var(--chakra-fontWeights-normal);

              text-align: left;
            }
          }
          .accordion-button-container {
            align-items: center;
            justify-content: flex-end;
            width: var(--chakra-sizes-10);
            height: var(--chakra-sizes-10);
            .accordion-expand {
              width: var(--chakra-sizes-10);
              height: var(--chakra-sizes-10);
            }
          }
          .accordion-title-button {
            margin-left: var(--chakra-space-4);
            text-transform: none;
          }
          .accordion-button-container-flex {
            flex: 1 0 25%;
          }
          .accordion-summary-button-flex {
            flex: 1 0 35%;
          }
        }
        .accordion-panel {
          padding: var(--chakra-space-10) var(--chakra-space-4)
            var(--chakra-space-4) var(--chakra-space-4);
          margin: 0 var(--chakra-space-16);
          .helpful-resource-heading {
            font-family: $font-family-regular;
            font-weight: var(--chakra-fontWeights-semibold);
            line-height: var(--chakra-lineHeights-8);
            margin-bottom: var(--chakra-space-3-2);
          }
          .resource-heading {
            font-family: $font-family-regular;
            font-weight: var(--chakra-fontWeights-semibold);
            line-height: var(--chakra-lineHeights-8);
            margin-bottom: var(--chakra-space-4);
          }
          .recent-products-text {
            font-size: $font-size-small;
            font-weight: var(--chakra-fontWeights-normal);
            line-height: var(--chakra-lineHeights-8);
            margin-top: var(--chakra-space-3-2);
            margin-bottom: var(--chakra-space-9-6);
            ul {
              margin: var(--chakra-space-4-8) 0 var(--chakra-space-4-8)
                var(--chakra-space-12);
              li {
                line-height: 24px;
              }
            }
          }
          .resource-containers:hover {
            background: $color-bg-badge;
          }
          .resource-containers {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: row;
            margin-bottom: var(--chakra-space-3-2) !important;
            color: $color-default-font-color;
            font-family: $font-family-regular;
            margin-top: 0;
            margin-bottom: var(--chakra-space-6);
            padding: var(--chakra-space-4) var(--chakra-space-6-4)
              var(--chakra-space-2-4) var(--chakra-space-6-4);
            .resource-title {
              font-weight: var(--chakra-fontWeights-semibold);
              margin-bottom: var(--chakra-space-2);
            }
            .resource-caption {
              font-size: $font-size-xs;
              font-weight: var(--chakra-fontWeights-normal);
              color: $color-stepper-indicator;
            }
          }
        }
      }

      .accordion-container-active {
        border-top: 1px solid $color-link-hover-color;
        border-bottom: 1px solid $color-link-hover-color;
        border-color: $color-link-hover-color;
        background-color: $color-sidebar-hover-bg;
        &.active-last-item {
          border-radius: 0 0 var(--chakra-space-5-6) var(--chakra-space-5-6);
          overflow: hidden;
        }
      }
    }
  }
}
