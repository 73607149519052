@import "_variables.scss";
.boxInterstitial{
    width:90%;
    height:auto;
    border-radius: var(--chakra-space-2);
    border: var(--chakra-space-px) solid $color-border;
    padding:$padding-large;
    gap:$padding-xl;
    background-color: $color-default-white;
    display: flex;
    flex-direction: row;
    z-index:2;
    margin-top: calc(var(--chakra-space-2) * -1);
    box-shadow: none;
    border: var(--chakra-space-px) solid $color-border;
}
.boxInterstial-active{
    width:95%;
    height: auto;
    border-radius: $border-radius-small;
    border: var(--chakra-space-px) solid $color-link-hover-color;
    padding:$padding-large;
    gap:$padding-xl;
    background-color: $color-default-white;
    display: flex;
    flex-direction: row;
    z-index:2;
    margin-top: calc(var(--chakra-space-2) * -1);
    box-shadow: $box-shadow-default;
}