@import "_variables.scss";
.intertitial-card-container {
  margin-top: 0px;
  width: 100%;
}
.image-interstial-screen {
  justify-content: center;
  display: flex;
  align-items: center;
  margin-bottom: -22px;
  flex-direction: column;
}
.box-interstial {
  width: 50%;
  margin: auto;
  margin-bottom: var(--chakra-space-80);
}
.top-heading-styling-interstial {
  font-family: $font-family-sfmono;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.04em;
  text-align: center;
  text-transform: uppercase;
  margin-top: var(--chakra-space-10);
  &.top-heading-styling-interstial-completed {
    color: $tag-completed-text-color;
  }
}

.interstial-screen-footer {
  min-height: 7.8rem;
  background: $color-btn-secondary-hover;
  margin: 0 auto;
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
