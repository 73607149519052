@import "_variables.scss";

.service-charge-container {
  margin-top: 30px;
  &:first-child {
    margin-top: 0;
  }
  .service-charge-frequency-duration-container {
    margin-top: 32px;
  }

  .periodic-service-charge-title {
    margin-top: 32px;
  }

  .periodic-service-charge-input {
    margin-top: 16px;
    width: 440px;
    display: flex;
    gap: 3rem;
    .chakra-numberinput__field {
      width: 100%;
    }
  }
}
