@import "_variables.scss";
.explore-finxact-content {
  align-items: center;
  padding: 0 var(--chakra-space-8);
  justify-content: space-between;
  margin-bottom: var(--chakra-space-12);
  h4 {
    font-size: $font-size-2rem;
  }
  .explore-finxact-status {
    font-size: $font-size-xs;
    font-weight: var(--chakra-fontWeights-semibold);
    text-align: right;
    margin-right: var(--chakra-space-4);
  }
}

@media screen and (max-width: 1307px) {
  .explore-finxact-stepper {
    .stepper-sep {
      top: calc(var(--stepper-indicator-size) + 27px);
    }
  }
}

.app-modal-content {
  &.app-modal-selectors {
    max-width: 638px !important;
    padding: var(--chakra-space-8);
    .app-modal-body {
      padding: var(--chakra-space-4-8);
      .chakra-modal__close-btn {
        width: 24px;
        height: 24px;
      }
      .chakra-modal__body {
        margin-top: var(--chakra-space-4-8);
        padding: 0px;
      }
    }
    .app-modal-title {
      padding: 0px;
    }
    .chakra-textarea,
    .chakra-input {
      width: 99%;
      &:focus-visible {
        margin: 0 var(--chakra-space-px);
      }
    }

    .chakra-textarea {
      padding: var(--chakra-space-4-8);
    }
    .chakra-modal__footer {
      border-color: $color-border;
    }
  }
}
