@import "_variables.scss";
.test-and-deploy-content {
  align-items: center;
  padding: 0 var(--chakra-space-8);
  justify-content: space-between;
  margin-bottom: var(--chakra-space-12);
  h4 {
    font-size: $font-size-2rem;
  }
  .test-and-deploy-status {
    font-size: $font-size-xs;
    font-weight: var(--chakra-fontWeights-semibold);
    text-align: right;
    margin-right: var(--chakra-space-4);
  }
}
