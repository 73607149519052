@import "_variables.scss";

.dashboard-container {
  grid-template-columns: repeat(7, 2fr);
  gap: 24px;
  padding: var(--chakra-space-12-8);
  overflow: auto;
  .dashboard-alert {
    margin-bottom: var(--chakra-space-4);

    a {
      color: $color-link-color;
      text-decoration: underline;
    }
  }

  .journey-container {
    padding: var(--chakra-space-12-8);
  }
  .dashboard-desc-text {
    font-size: 1.6rem;
    font-weight: 400;
    margin-top: 8px;
  }
  .dashboard-carousel-container {
    z-index: 98;
  }
  .dashboard-progress {
    position: relative;
    padding: var(--chakra-space-12) 0;
    justify-content: space-between;
    .react-multi-carousel-track {
      flex-grow: 1;
    }
    .carousel-item {
      width: 155px !important;
      height: 105px;
    }
    .dashboard-progress-box {
      width: 100%;
      height: 100%;
      padding-top: var(--chakra-space-6);
      padding-bottom: var(--chakra-space-6);
      border-radius: 8px;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      .icon-img {
        z-index: 99;
        background-color: $color-default-white;
      }

      .dashboard-progress-item-name {
        font-weight: 400;
        text-align: center;
        margin: 12px 20px 0 20px;
      }
    }

    .active {
      background-color: $color-btn-secondary-hover;
      z-index: 99;
      .icon-img {
        background-color: $color-btn-secondary-hover;
      }
      .dashboard-progress-item-name {
        font-weight: 600;
      }
      .dashboard-progress-line {
        border-color: transparent !important;
      }
      &::before {
        content: "";
        background: url("../../../public/assets/images/up-arrow.svg") no-repeat;
        width: 25px;
        height: 24px;
        top: 110px;
        position: absolute;
      }
    }

    .dashboard-progress-box:hover {
      z-index: 99;
      background-color: $color-toast-info-notification;
      .icon-img {
        background-color: $color-toast-info-notification;
      }
      .dashboard-progress-line {
        border-color: transparent !important;
      }
    }
  }
  .dashboard-progress-line {
    width: 100%;
    border-top: 1px dashed $color-description-txt;
    &.invisible {
      border-color: transparent !important;
    }
  }

  .dashboard-main-content-container {
    padding: var(--chakra-space-13) 0 0 0;
    border-radius: var(--chakra-space-6);
    border: 1px solid $color-link-hover-color;
    position: relative;
    top: -4px;
  }

  .recent-product-section {
    padding: 0;
  }

  .right-panel {
    .dashboard-env-content {
      box-shadow: 0px 4px 14px 0px $box-shadow-status-sidebar;
      margin-top: 22px;
      border-radius: 16px;
    }
    .dashboard-right-contents {
      margin-top: 32px;
      border-top: 1px solid $color-border;
      padding-top: 32px;
      p {
        font-size: 1.6rem;
        font-weight: 400;
      }
      .links {
        margin-top: 14px;
        .chakra-icon {
          width: 24px;
          height: 24px;
        }
      }
      .announcements-title {
        font-family: $font-family-regular;
        font-size: $font-size-reg;
        font-weight: $font-weight-bold;
        line-height: $line-height-medium;
        text-align: left;
        color: $color-link-hover-color;
        margin-top: var(--chakra-space-2-4);
        margin-bottom: var(--chakra-space-2-4);
      }
      .announcements-date {
        font-family: $font-family-sfmono;
        font-size: $font-size-small;
        font-weight: $font-weight-medium;
        text-align: left;
        line-height: $line-height-small;
        margin-bottom: var(--chakra-space-2-4);
      }
      .announcements-description {
        font-family: $font-family-regular;
        font-size: $font-size-reg;
        font-weight: $font-weight-regular;
        line-height: $line-height-medium;
        text-align: left;
        .announcements-html-description {
          max-height: var(--chakra-space-140);
          overflow: auto;
          text-overflow: ellipsis;
          margin-bottom: var(--chakra-space-8);
          padding-right: var(--chakra-space-4);
          h1 {
            font-size: $font-size-med;
          }
          p {
            font-size: $font-size-small;
          }
        }
      }
      .app-btn-link {
        font-family: $font-family-regular;
        font-size: $font-size-reg;
        font-weight: $font-weight-bold;
        line-height: $line-height-xs;
        margin-top: var(--chakra-space-8-8);
        margin-bottom: var(--chakra-space-8-8);
      }
      .link-arrow {
        font-size: $line-height-xlarge;
      }
    }
  }
  .carousel-arrow-right {
    top: 160px;
    right: -15px;
    &:hover {
      background: none;
    }
  }
  .carousel-arrow-left {
    top: 160px;
    left: -15px;
    &:hover {
      background: none;
    }
  }
}

@media screen and (max-width: 1440px) {
  .top-dashboard-div {
    margin-left: var(--chakra-space-32);
  }
}
