@import "_variables.scss";
.product-configuration-contents {
  align-items: center;
  padding: 0 var(--chakra-space-8);
  justify-content: space-between;
  margin-bottom: var(--chakra-space-12);

  h4 {
    font-size: $font-size-2rem;
  }
  .product-configuration-status {
    text-align: right;
    flex: 1 0 15%;
    font-size: $font-size-xs;
    font-weight: var(--chakra-fontWeights-semibold);
    text-align: right;
    margin-right: var(--chakra-space-4);
  }
}

.custom-desc-format {
  display: block;
  font-size: $font-size-small;
  margin-bottom: var(--chakra-space-4);
}

.custom-btn-format {
  display: block;
  font-size: $font-size-small;
  font-weight: var(--chakra-fontWeights-bold);
  margin-bottom: var(--chakra-space-6);
}

.view-more {
  display: block;
}

.view-less {
  display: none;
}

.badge-container {
  display: flex;
  margin: var(--chakra-space-8);
  .tag-default {
    margin-right: var(--chakra-space-4);
    width: auto;
  }
}
