@import "_variables.scss";
.title-content {
  height: 118px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.content-complete {
  width: 118px;
  height: 118px;
  border: 1px solid $color-nav-selected;
  border-radius: 50%;
  text-align: center;
  font-family: $font-family-regular;
  p {
    margin: 28px auto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    span {
      font-family: $font-family-sfmono;
      font-size: $font-size-32;
      font-weight: $font-weight-semi-bold;
      line-height: $line-height-xxlarge;
      padding-left: var(--chakra-space-2);
      padding-right: var(--chakra-space-2);
    }
  }
}
.content-title-text {
  margin-top: 15px;
  margin-left: 30px;
  width: 80%;
  height: 80%;
  h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  }
  p {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $color-font-card;
  }
}
.content-divider {
  width: auto;
  margin-left: calc(var(--chakra-space-12-8) * -1);
  margin-right: calc(var(--chakra-space-12-8) * -1);
  margin-top: var(--chakra-space-12-8) ;
  margin-bottom: var(--chakra-space-12-8);
  border: var(--chakra-space-px) solid $color-border;
}
.title-box {
  height: 200px;
}
