@import "_variables.scss";

.product-card-wrapper {
  border-bottom: 1px solid $color-border;
  padding: 28px 10px;
  &.product-selected {
    border-color: $color-link-hover-color !important;
    box-shadow: $box-shadow-default;
    border-width: 0.2rem;
  }
}

.product-card-container {
  img {
    width: 3.8rem;
    height: 3.8rem;
    align-self: flex-start;
  }
  .product-card-content {
    margin: 0px 20px;
    .container-width{
      font-weight: 600;
      font-size: $font-size-med
    }
  }
  .app-btn-product-card {
    padding: 0px var(--chakra-space-10);
    align-self: center;
    min-width: 127px;
    margin-left: auto;
    background: transparent;
  }
}
