@import "_variables.scss";

  .box-container-flex {
    display: flex;
    flex-direction: row;
  }
  .custom-table-data-row-us-specific {
    padding: var(--chakra-space-6);
    border-bottom: var(--chakra-space-px) solid $color-border;
    line-height: var(--chakra-space-7);
  }
  .neg-limit-drop{
    margin-bottom: var(--chakra-space-9);
    margin-top: -var(--chakra-space-3);
   
  }
  .custom-table-padding {
    padding-top: calc(var(--chakra-space-6) + var(--chakra-space-px));
    padding-bottom: calc(var(--chakra-space-6) + var(--chakra-space-px));
  }
  .table {
    border-radius: calc(var(--chakra-space-16) + var(--chakra-space-px)*8);
  }

  .custom-table-header {
    width: var(--chakra-space-12);
    border-top-left-radius: var(--chakra-space-2);
  }
  .custom-table-tr {
    height: calc(var(--chakra-space-16) + var(--chakra-space-px)*6);
    border-color: $color-border;
    border-top-left-radius: var(--chakra-space-2);
    
  }
.custom-table-th-header {
      opacity: 0;
    }
  .custom-table-data-last-row {
    padding: var(--chakra-space-6);
    border-bottom: var(--chakra-space-px) solid $color-nav-selected;
    line-height: var(--chakra-space-7);
  }
  .custom-table-th {
    text-transform: none;
    width: 70%;
    background-color: $color-bg-badge;
    border-top-left-radius: var(--chakra-space-3);
    p{
      font-size: $font-size-reg;
    }
  }

  .custom-table-th-right {
    width: 30%;
    background-color: $color-bg-badge;
    border-top-right-radius: var(--chakra-space-3);
  }

