@import "_variables.scss";
.api-call-tab{
    padding: var(--chakra-space-16) var(--chakra-space-60);
    .apis-description{
        p{
            padding-bottom: var(--chakra-space-3-2);
            font-size: $font-size-reg;
            font-weight: $font-weight-regular;
            line-height: $font-size-l;
            text-align: left;
            color: $color-font-card;
        }
        ul {
            margin: 0 0 0 var(--chakra-space-5)!important;
            li {
                margin-left: 0 !important;
                line-height: $font-size-2rem !important;
                margin-top: 0;
            }
            a{
                font-size: $font-size-small;
                font-weight: $font-weight-semi-bold;
                line-height: $font-size-2rem;
                text-align: left;
                color: $color-surface-text-blue;
                &:hover {
                  text-decoration: underline;
                  color: $color-btn-link-hover;
                }
            }
        }
    }
    .tab-download{
        width: 100%;
        justify-content: space-between;
    }
    .custom-table-data-row{
        font-weight: $font-weight-semi-bold;
    }
    .custom-table-container .is-last-element{
        text-align: left;
    }
}