@import "_variables.scss";

.dashboard-env-content {
  .environment-container {
    margin-top: 0;
    .current-env {
      border-top-right-radius: var(--chakra-space-6-4);
      border-top-left-radius: var(--chakra-space-6-4);
      padding: var(--chakra-space-12-8);
      background-color: $color-btn-secondary-hover;
      .env-heading {
        font-size: $font-size-med;
        font-weight: $font-weight-semi-bold;
      }
      .current-env-details {
        align-items: center;
        margin-top: var(--chakra-space-3-2);
        .env-status-err {
          color: $color-default-danger;
        }
        .env-status-success {
          color: $color-default-success;
        }
      }
      .env-name {
        font-weight: $font-weight-semi-bold;
        margin-left: var(--chakra-space-3);
      }
      .env-name-desc {
        font-size: $font-size-xs;
        font-weight: $font-weight-regular;
        margin-top: var(--chakra-space-1);
        margin-left: var(--chakra-space-8);
      }
      .env-upgrade {
        margin-top: var(--chakra-space-3-2);
        align-items: center;
        .env-upgrade-text {
          font-size: $font-size-small;
          font-weight: $font-weight-semi-bold;
          color: $color-link-hover-color;
          margin-left: var(--chakra-space-3-2);
        }
      }
      .other-env-heading {
        font-size: $font-size-reg;
        font-weight: $font-weight-semi-bold;
      }
    }

    .other-info {
      background-color: $color-default-white;
      border-bottom-right-radius: var(--chakra-space-6-4);
      border-bottom-left-radius: var(--chakra-space-6-4);
      .env-link {
        font-size: $font-size-small;
        font-weight: $font-weight-semi-bold;
        margin-top: var(--chakra-space-12-8);
        cursor: pointer;
      }
      .env-price-text {
        font-size: $font-size-xs;
        font-weight: $font-weight-regular;
      }
    }
  }
}
