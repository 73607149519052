@import "_variables.scss";

.default-env-content {
  padding: 32px;
  .env-heading {
    font-size: 1.8rem;
    font-weight: 600;
  }
  .env-name {
    font-weight: 600;
    margin-top: 8px;
  }
  .env-link {
    font-size: 1.4rem;
    margin-top: 16px;
    cursor: pointer;
  }
  .env-price-text {
    font-size: 1.2rem;
    font-weight: 400;
  }
}
