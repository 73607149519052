@import "variables";

/* ----------------------------------------------------------------------------------- */
/*  Exports the majority of our colors to CSS variables so that they can be swapped    */
/*  on the fly in order to switch to dark mode.                                        */
/* ----------------------------------------------------------------------------------- */

// Light Mode CSS Vars
:root {
  // FAST
  --fxt-white: #{$color-default-white};
  --fast-orange-1: #{$color-gradient-light-orange};
  --fast-blue-1: #{$fast-blue-1};
  --fast-blue-2: #{$fast-blue-2};
  --fast-blue-3: #{$fast-blue-3};
  --fast-blue-4: #{$fast-blue-4};
  --fast-blue-5: #{$fast-blue-5};
  --fast-gray-1: #{$fast-gray-1};
  --fast-gray-2: #{$fast-gray-2};
  --fast-gray-3: #{$fast-gray-3};
  --fast-gray-4: #{$fast-gray-4};
  --fxt-emphasis: #{$color-emphasis};
}

// Dark Mode CSS Vars
@media (prefers-color-scheme: dark) {
  :root {
    --fxt-white: #{$color-gray-800};
    --fast-gray-1: #{$color-default-white};
    --fast-gray-2: #{$fast-gray-4};
    --fast-gray-3: #{$fast-gray-3};
    
    --fast-blue-1: #{$fast-blue-3};
    --fast-blue-2: #{$fast-blue-3};
    --fast-blue-3: #{$fast-blue-3}; // Pivot point
    --fast-blue-4: #{$fast-blue-1};
  }
}